<template>
  <div>
    <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
    <b-row>
      <b-col>
        <div v-if='notifications.length > 0'>
          <b-card :key="indextr" v-for="(tr, indextr) in notifications">
            <div
              class="d-flex justify-content-start align-items-center mb-1"
            >
              <div class="profile-user-info">
                <h6 class="mb-0">
                  {{tr.title}}
                </h6>
                <small class="text-muted">
                  {{tr.created_at | dateFormat}}
                </small>
              </div>
            </div>
            <b-card-text v-html="tr.message"></b-card-text>
          </b-card>
        </div>
        
        <b-card v-else>
          <div class="text-center">
            <h2>
              Nenhuma notificação por enquanto!
            </h2>
            <p>
            <p class="mt-2">
              <b-img
                fluid
                width="500"
                :src="require('@/assets/images/pages/notify.svg')"
              />
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    </b-overlay>
  </div>
</template>
<script>
/* eslint-disable */
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      Selected: "class",

      class_id: null,
      user_id: null,
      isLoading: true,

      classList:[],

      dataMessage: {
        title: null,
        message: null
      }
    }
  },
  computed: {
    ...mapState("classTeacher", ["classTeacher"]),
    ...mapState("classes", ["classShow"]),
    ...mapState("notifications", ["notifications"]),
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
  created(){
    this.fetchNotification();
    this.fetchClassTeacher();
  },
  methods: {
    ...mapActions("notifications", ["store"]),
    sendMessage(){
      this.isLoading = true
      if(this.Selected == "class"){
        this.dataMessage.class_id = this.class_id
      }
      if(this.Selected == "student"){
        this.dataMessage.class_id = this.class_id
        this.dataMessage.user_id = this.user_id
      }
      this.store({
        ...this.dataMessage,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          }
        })
        this.clearData();
        this.fetchNotification()
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    clearData(){
      this.dataMessage = {
        title: null,
        message: null,
      }
      this.class_id = null
      this.user_id = null
    },

    fetchClassTeacher(){
      let user = JSON.parse(localStorage.getItem('userData'))
      if(user.type == 'TEACHER'){
        this.isLoading = true
        this.$store
          .dispatch("classTeacher/fetchClassTeacher")
          .then((response) => {
            response.forEach((value, index) => {
              value.classes.forEach((valueX, indexX) => {
                this.classList.push(valueX)
              })
            })
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            this.isLoading = false
         })
      }else{
        return this.isLoading = false
      }
    },

    fetchClassDetails(paginated = false, page = 1){
      this.$store.dispatch("classes/fetchDetailsClass", {
          class_id: this.class_id,
          paginated: paginated,
          page: page,
        }).finally(() => {
        });
    },

    fetchNotification() {
      this.$store
        .dispatch("notifications/fetchNotifications")
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },
  },
   watch: {
    class_id(val){
      if(val != null){
        this.fetchClassDetails()
      }
    },
  },
}
</script>
